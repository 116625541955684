<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.userId"
          size='small'
          style="margin-left: 5px;width: 220px"
          placeholder='用户Id'
      ></a-input>
      <a-input
          allowClear
          v-model="params.userName"
          size='small'
          style="margin-left: 5px;width: 220px"
          placeholder='用户名称'
      ></a-input>
      <a-select
          @search="searchData"
          v-model="params.coinId"
          :filter-option="untils.filterOption"
          showSearch
          allowClear
          size="small"
          style="margin-left: 5px;width: 220px"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="(item, index) of bigList"
            :key="index"
            :value="item.id"
        >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <a-button
          @click="search"
          type="primary"
          class="ml-5"
          size="small"
      >搜索</a-button>
      <a-button
          type="default"
          @click="addAuth"
          class="ml-5"
          size="small"
      >添加</a-button>
    </div>
    <div class="table-content">
      <ExpertOrNormalOrRatingPageTable
          :list="listData"
          page-type="normal"
          :pagination="pagination"
          @deleteItem="getList"
          @changePage="changePage"
      />
    </div>
    <ExpertOrNormalAdd
        ref="expertOrNormalAddEl"
        @addOrEditorSuccess="addSuccess"
    />
  </div>
</template>
<script>
import untils from "@/untils";
import ExpertOrNormalOrRatingPageTable from "@/views/cmsPage/versionManage/versionAuthAbout/_components/ExpertOrNormalOrRatingPageTable"
import ExpertOrNormalAdd from "@/views/cmsPage/versionManage/versionAuthAbout/_components/ExpertOrNormalAdd"
import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    ExpertOrNormalOrRatingPageTable, ExpertOrNormalAdd
  },
  data() {
    return {
      untils,
      listData: [],
      bigList: [],
      params: {
        userId: undefined,
        userName: undefined,
        coinId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /** 添加权限 */
    addAuth() {
      this.$refs.expertOrNormalAddEl.show('', 'normal')
    },
    /** 添加成功 */
    addSuccess() {
      this.getList();
    },
    /** 获取列表数据 */
    async getList() {
      const res = await this.axios("/dq_admin/duibanNormalAuthCoin/getAuthorityList", {
        params: this.params
      });
      if (res.status != 200) return;
      this.listData = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 获取大币种列表 */
    async searchData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.bigList = records
    },
    changePage(page) {
      const { current } = page
      this.pagination.current = current;
      this.params.pageNum = current
      this.getList();
    },
    search() {
      this.pagination.current = 1
      this.params.pageNum = 1
      this.getList()
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    margin-top: 10px;
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.bgf {
  background-color: #fff !important;
  border: 1px solid #000c17 !important;
  color: #000c17 !important;
  margin-left: 20px !important;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
.ml-5 {
  margin-left: 5px;
}
</style>
